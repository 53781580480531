.page_container {
    padding: 0 2rem;
}

.page_header {
    display: grid;
    padding: 1rem;
    align-items: center;
    grid-template-columns: 30% 40% 30%;
}

.page_header_left {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
}

.page_header_title {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.page_header_right {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
}

.summary {
    padding: 1rem;
    margin: 1rem;
    background-color: #565e63;
    border-radius: 0.4rem;
}

.summary_small_display {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.summary_large_display {
    display: none;
    text-align: center;
    align-items: center;
    justify-content: center;
}

.summary_title {
    font-weight: bold;
    border-top: black solid 1px;
    border-bottom: 1px black solid;
}

@media only screen and (min-width:430px) {
    .summary_small_display {
        display: none;
    }
    .summary_large_display {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(5rem, 1fr));
        column-gap: 1rem;
    }
    
    .summary {
        margin: 0;
        background-color: transparent;
        border-radius: 0;
        &:hover:not(.summary_title) {
            background-color: #dddada;
        }
    }
}
