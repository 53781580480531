
.form {
    padding-bottom: 1.5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.validation {
    color: #a91313;
    padding-bottom: 0.25rem;
    text-align: left;
    font-weight: bold;
}
@media only screen and (min-width:450px) {
    .form {
        padding-bottom: 2rem;
    }
}
@media only screen and (min-width:50em) {
    .form {
        padding-bottom: 3rem;
    }
}