.button_container, .button_close {
  justify-content: center;
  align-items: center;
  display: flex;
}

.button_close:hover {
  cursor: pointer;
  -webkit-filter: drop-shadow(0 4px 4px rgba(0, 0, 0, .25));
  filter: drop-shadow(0 4px 4px rgba(0, 0, 0, .25));
}

.button_close:hover svg {
  fill: #f3f3f3;
}

.button {
  -webkit-filter: drop-shadow(0 1px 1px rgba(0, 0, 0, .25));
  filter: drop-shadow(0 1px 1px rgba(0, 0, 0, .25));
  text-align: center;
  background-color: rgba(0, 0, 0, 0);
  border-radius: 4px;
  padding: 5px 10px;
  font-weight: bold;
}

.button:hover:not(.btn_disabled) {
  cursor: pointer;
  color: #fff;
  box-shadow: 0 4px 4px rgba(0, 0, 0, .25);
}

.button.btn_disabled {
  opacity: .45;
}

.button_add {
  color: #fff;
  background-color: #228419;
  border: 1px solid #228419;
  box-shadow: 0 2px 2px rgba(0, 0, 0, .25);
}

.button_add:hover:not(.btn_disabled) {
  background-color: #1f7c17;
}

.button_primary {
  color: #194c84;
  border: 1px solid #194c84;
}

.button_primary:hover:not(.btn_disabled) {
  background-color: #194c84;
}

.button_danger {
  color: #841919;
  border: 1px solid #841919;
}

.button_danger:hover:not(.btn_disabled) {
  background-color: #841919;
}

.modal_popup_container {
  z-index: 100;
  -webkit-backdrop-filter: blur(1px);
  backdrop-filter: blur(1px);
  background-color: rgba(163, 163, 163, .33);
  justify-content: center;
  align-items: center;
  display: flex;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.relative_positioner {
  -webkit-filter: drop-shadow(0 4px 4px rgba(0, 0, 0, .25));
  filter: drop-shadow(0 4px 4px rgba(0, 0, 0, .25));
  position: relative;
}

.modal_popup {
  max-height: 90vh;
  max-width: 90vw;
  background-color: #91908d;
  border-radius: 4px;
  flex-direction: column;
  display: flex;
  overflow: hidden;
  box-shadow: 0 4px 4px rgba(0, 0, 0, .25);
}

.modal_element, .modal_body, .modal_header {
  padding-left: 2rem;
  padding-right: 2rem;
}

.modal_header {
  -webkit-filter: drop-shadow(0 4px 4px rgba(0, 0, 0, .25));
  filter: drop-shadow(0 4px 4px rgba(0, 0, 0, .25));
  background-color: #676f76;
  align-items: center;
  padding-top: 1rem;
  padding-bottom: 1rem;
  display: flex;
}

.modal_title {
  flex: 1;
}

.modal_body {
  margin-bottom: .5rem;
  padding-top: 2rem;
  padding-bottom: 2rem;
  overflow: auto;
}

.modal_button_container {
  width: 100%;
  justify-content: flex-end;
  display: flex;
}

.modal_button_left_padder {
  flex: 1;
}

.form {
  flex-direction: column;
  align-items: center;
  padding-bottom: 1.5rem;
  display: flex;
}

.validation {
  color: #a91313;
  text-align: left;
  padding-bottom: .25rem;
  font-weight: bold;
}

@media only screen and (min-width: 450px) {
  .form {
    padding-bottom: 2rem;
  }
}

@media only screen and (min-width: 50em) {
  .form {
    padding-bottom: 3rem;
  }
}

.main_text, #login_form .form_input, #contact_us_form .form_input {
  font-size: 20px;
}

@media only screen and (min-width: 450px) {
  .main_text, #login_form .form_input, #contact_us_form .form_input {
    font-size: 25px;
  }
}

@media only screen and (min-width: 1350px) {
  .main_text, #login_form .form_input, #contact_us_form .form_input {
    font-size: 30px;
  }
}

.form_input_container {
  width: 100%;
  padding-bottom: .7rem;
}

.form_validation_and_add_container {
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin-bottom: .5rem;
  display: flex;
}

.form_add_new .button {
  color: #fff;
  box-shadow: none;
  background-color: rgba(0, 0, 0, 0);
  border: none;
  text-decoration: underline;
}

.form_add_new .button:hover {
  box-shadow: none;
  -webkit-filter: drop-shadow(0 4px 4px rgba(0, 0, 0, .25));
  filter: drop-shadow(0 4px 4px rgba(0, 0, 0, .25));
  background-color: rgba(0, 0, 0, 0);
  text-decoration-thickness: 3px;
}

.form_container {
  width: calc(100% - 1rem);
}

.form_container div {
  outline-color: rgba(255, 255, 255, .6);
}

.form_input_label {
  font-size: 16px;
}

.form_input {
  height: 36px;
  background-color: rgba(196, 196, 196, .18);
  border: 0 solid;
  border-bottom: 2px solid #fff;
  padding: .3rem .5rem .2rem;
  font-family: Arial;
  font-size: 17px;
}

.form_input:hover {
  background-color: rgba(196, 196, 196, .05);
}

.form_input:hover, .form_input:focus {
  border-color: #2fcc8b;
}

.form_input:focus {
  border-color: #2fcc8b;
  border-bottom-style: solid;
  border-bottom-width: 1.5px;
  outline: .5px solid #2fcc8b;
}

.react-datepicker-wrapper {
  width: 100%;
}

textarea {
  resize: none;
  min-height: 120px;
  overflow: hidden;
}

@media only screen and (min-width: 450px) {
  .form_input_container {
    padding-bottom: .8rem;
  }

  .form_container {
    width: 20rem;
  }
}

@media only screen and (min-width: 50em) {
  .form_input_container {
    align-items: center;
    display: flex;
  }

  .form_input_label {
    width: 10vw;
    text-align: right;
    padding-right: 2rem;
  }
}

@media only screen and (min-width: 1058px) {
  .form_container {
    width: 30vw;
    max-width: 30vw;
  }
}

.page_container {
  padding: 0 2rem;
}

.page_header {
  grid-template-columns: 30% 40% 30%;
  align-items: center;
  padding: 1rem;
  display: grid;
}

.page_header_left {
  width: 100%;
  justify-content: flex-start;
  align-items: center;
  display: flex;
}

.page_header_title {
  width: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
}

.page_header_right {
  width: 100%;
  justify-content: flex-end;
  align-items: center;
  display: flex;
}

.summary {
  background-color: #565e63;
  border-radius: .4rem;
  margin: 1rem;
  padding: 1rem;
}

.summary_small_display {
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.summary_large_display {
  text-align: center;
  justify-content: center;
  align-items: center;
  display: none;
}

.summary_title {
  border-top: 1px solid #000;
  border-bottom: 1px solid #000;
  font-weight: bold;
}

@media only screen and (min-width: 430px) {
  .summary_small_display {
    display: none;
  }

  .summary_large_display {
    grid-template-columns: repeat(auto-fit, minmax(5rem, 1fr));
    column-gap: 1rem;
    display: grid;
  }

  .summary {
    background-color: rgba(0, 0, 0, 0);
    border-radius: 0;
    margin: 0;
  }

  .summary:hover:not(.summary_title) {
    background-color: #dddada;
  }
}

.user_summary_container .summary_large_display {
  grid-template-columns: minmax(5rem, 10%) 1fr minmax(5rem, 10%);
}

/*# sourceMappingURL=UserPage.8e47a84d.css.map */
