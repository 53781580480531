@import "../../constants";

.button_container {
    display: flex;
    align-items: center;
    justify-content: center;
}

.button_close {
    @extend .button_container;
    &:hover {
        cursor: pointer;
        filter: $shadow;
        svg {
            fill: #f3f3f3;
        }
    }
}

.button {
    background-color: transparent;
    font-weight: bold;
    padding: 5px 10px 5px 10px;
    border-radius: 4px;
    // box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
    filter: drop-shadow(0px 1px 1px rgba(0, 0, 0, 0.25));
    text-align: center;
    &:hover:not(.btn_disabled) {
        cursor: pointer;
        color: white;
        box-shadow: $shadow_props;
    }
    &.btn_disabled {
        opacity: 0.45;
    }
}

$add_color: #228419;



.button_add {
    background-color: $add_color;
    color: white;
    border: solid 1px $add_color;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
    &:hover:not(.btn_disabled) {
        background-color: #1f7c17;
    }
}

$primary_color: #194c84;

.button_primary {
    color: $primary_color;
    border: solid 1px $primary_color;
    &:hover:not(.btn_disabled) {
        background-color: $primary_color;
    }
}

$danger_color: #841919;

.button_danger {
    color: $danger_color;
    border: solid 1px $danger_color;
    &:hover:not(.btn_disabled) {
        background-color: $danger_color;
    }
}
