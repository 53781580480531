@import "../constants";

.modal_popup_container {
    position: fixed;
    top:0;
    left:0;
    right:0;
    bottom: 0;
    z-index: 100;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(163, 163, 163, 0.33);
    backdrop-filter: blur(1px);
}

.relative_positioner {
    position: relative;
    filter: $shadow;
}

.modal_popup {
    display: flex;
    flex-direction: column;
    background-color: rgb(145, 144, 141);
    max-height: 90vh;
    max-width: 90vw;
    overflow: hidden;
    border-radius: 4px;
    box-shadow: $shadow_props;
}

.modal_element {
    padding-left: 2rem;
    padding-right: 2rem;
}

.modal_header {
    @extend .modal_element;
    background-color: $main_color;
    filter: $shadow;
    display: flex;
    align-items: center;
    padding-top: 1rem;
    padding-bottom: 1rem;
}

.modal_title {
    flex: 1;
}

.modal_body {
    @extend .modal_element;
    overflow: auto;
    padding-top: 2rem;
    padding-bottom: 2rem;
    margin-bottom: 0.5rem;
}

.modal_button_container {
    display: flex;
    justify-content: flex-end;
    width: 100%;
}

.modal_button_left_padder {
    flex: 1;
}
